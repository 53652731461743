import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';

import Container from 'components/Container';

import agProfile from './assets/ag_profile.jpg';
import ccProfile from './assets/cc_profile.jpg';

const mock = [
  {
    name: 'Andy Gibson',
    title: 'Business Lead',
    avatar: agProfile,
    about: '',
  },
  {
    name: 'Chris Canal',
    title: 'Technical Lead',
    avatar: ccProfile,
    about: '',
  },
];

const WithSimpleCards = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Container>
      <Box>
        <Grid container spacing={2}>
          {mock.map((item, i) => (
            <Grid item xs={12} sm={6} md={6} key={i}>
              <Box
                component={Card}
                boxShadow={2}
                sx={{
                  textDecoration: 'none',
                  transition: 'all .2s ease-in-out',
                  '&:hover': {
                    transform: `translateY(-${theme.spacing(1 / 2)})`,
                  },
                }}
              >
                <CardContent>
                  <Box
                    component={Avatar}
                    src={item.avatar}
                    height={80}
                    width={80}
                  />
                  <Box marginTop={4}>
                    <ListItemText primary={item.name} secondary={item.title} />
                    <Typography variant={'subtitle2'} color={'text.secondary'}>
                      {item.about}
                    </Typography>
                  </Box>
                </CardContent>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default WithSimpleCards;
