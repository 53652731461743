import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Container from 'components/Container';
import {
  GetStarted,
  Features,
  QuickStart,
  Benefits,
  Hero,
  Who,
  What,
} from './components';

const IndexView = (): JSX.Element => {
  const theme = useTheme();
  return (
    // <Box sx={{ overflowX: 'hidden' }}>
    <Box minHeight={'100vh'}>
      <Main bgcolor={'background.paper'}>
        <Hero />
        <Box
          sx={{
            backgroundImage: `linear-gradient(to bottom, ${alpha(
              theme.palette.background.paper,
              0,
            )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
            backgroundRepeat: 'repeat-x',
            position: 'relative',
          }}
        >
          {/* <Box
            id="home__what-item--js-scroll"
            minHeight={'100vh'}
            paddingTop={'100px'}
          >
            <Container>
              <What />
            </Container>
          </Box>
          <Box
            id="home__how-item--js-scroll"
            bgcolor={'alternate.main'}
            minHeight={'100vh'}
            paddingTop={'100px'}
          >
            <Container maxWidth={600}>
              <QuickStart />
            </Container>
          </Box>
          <Box
            id="home__why-item--js-scroll"
            minHeight={'100vh'}
            paddingTop={'100px'}
          >
            <Container>
              <Features />
            </Container>
          </Box>
          <Box
            id="home__who-item--js-scroll"
            minHeight={'100vh'}
            paddingTop={'100px'}
          >
            <Container>
              <Who />
            </Container>
          </Box>
          <Box
            id="home__when-item--js-scroll"
            minHeight={'100vh'}
            paddingTop={'100px'}
          >
            <Container>
              <Benefits />
            </Container>
          </Box> */}
          {/* <Box
            component={'svg'}
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
            sx={{
              width: '100%',
              marginBottom: theme.spacing(-1),
            }}
          >
            <path
              fill={theme.palette.background.paper}
              d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
            ></path>
          </Box> */}
        </Box>
        {/* <Container>
          <GetStarted />
        </Container> */}
      </Main>
    </Box>
  );
};

export default IndexView;
